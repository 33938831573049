.popupMenu {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;

    justify-items: center;
    align-items: center;
}

.popupMenu_active {
    visibility: visible;
    opacity: 1;
}

.popupMenu__container {
    margin-left: calc(100% - 68%);
    width: 68%;
    background-color: #fff;

    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;
}

.popupMenu__links {
    margin: 159px 0 0;
    padding: 0;

    list-style: none;
}

.popupMenu__link {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 500;

}

.popupMenu__links-link {
    text-align: center;
    padding: 0 0 28px;
    margin: 0;
}

.popupMenu__link:hover {
    cursor: pointer;
    opacity: .7;
}

.popupMenu__link-active {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 500;

    width: max-content;
    padding-bottom: 4px;
    position: relative;

    border-bottom: 2px solid #000000;
}


.popupMenu__links-link:last-child {
    padding: 0;
}

.popupMenu__button {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    padding: 8px 20px;

    border: none;
    border-radius: 20px;
    background: #F9F9F9;

    margin: 0 auto 90px;
    text-decoration: none;
}

.popupMenu__button {
    cursor: pointer;
    opacity: .8;
}


@media screen and (max-width: 360px) {
    .popupMenu__container {
        width: 80%;
        margin-left: 20%;
    }

    .popupMenu__links {
        margin: 144px 0 0;
    }

    .popupMenu__link {
        padding-bottom: 24px;
    }

    .popupMenu__link-active {
        padding-bottom: 7px; 
        margin-bottom: 15px;
    }

    .popupMenu__button {
        margin-bottom: 46px;
    }
}