.reviews__section {
    max-width: 100%;
    margin: 65px auto 0;
    padding: 65px 0;

    background-color: #f5f5f5;
}

.reviews {
    max-width: 1220px;
    margin: 0 auto;
}

.slick-slider {
    margin: 70px 20px;
    padding: 0 40px;

    border: 1px solid #000;
    border-radius: 5px;

    min-height: 700px;

    display: flex;
    align-items: center;
}

.slick-dots {
    left: 0;
}

.review__title {
    padding: 0;
    margin: 0 0 30px;

    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-align: center;
}

.review__text {
    padding: 0 20px;
    margin: 10px 0 0;

    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;

    text-align: justify;
}

@media screen and (max-width: 1320px) {
    .reviews {
        max-width: 100%;
        width: calc(100% - 60px);
        margin: 0 30px 0;
    }
}

@media screen and (max-width: 850px) {
    .review__title {
        font-size: 24px;
    }

    .review__text {
        padding: 0 20px;
        margin: 5px 0 0;
        color: #000;
        font-size: 16px;
        line-height: 28px;
    }
}

@media screen and (max-width: 750px) {
    .review__title {
        padding-top: 10px;
    }

    .review__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .review__text {
        font-size: 14px;
        line-height: 20px;
    }

    .slick-slider {
        margin: 70px 20px;
        padding: 0 10px;
        min-height: 600px
    }

    .review__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .review__text {
        font-size: 12px;
        line-height: 18px;
    }

    .slick-slider {
        margin: 45px 20px;
    }

    .review__title {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .review__text {
        font-size: 12px;
        line-height: 14px;
    }

    .slick-slider {
        margin: 45px 20px;
    }

    .review__title {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .slick-slider {
        margin: 45px 0;
    }

    .review__text {
        padding: 0 10px;
    }
}