.promo {
    max-width: 100%;
    width: 100%;
    padding: 65px 0 65px;

    background-color: #eeeeee;
}

.promo__container {
    max-width: 1280px;
    width: calc(100% - 60px);
    margin: 0 auto;

    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 278px);

    column-gap: 19px;
    row-gap: 14px;

    grid-template-areas:
        "A A B"
        "A A C";
}

.promo__post {
    border-radius: 5px;

    background-position: center center;
    background-size: fill;

    position: relative;
}

.promo__post-img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: 855px;
    max-height: 570px;

    object-fit: cover;
}

.promo__post:first-child {
    grid-area: A;
}

.promo__post:nth-child(2) {
    grid-area: B;
}

.promo__post:nth-child(3) {
    grid-area: C;
}

.promo__post:hover {
    .promo__post-img {
        filter: brightness(0.5) blur(1.5px);
    }

    .promo__type,
    .promo__name,
    .promo__address {
        opacity: 1;

    }
}

.promo__type {
    width: 100%;

    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 43px;

    position: absolute;

    top: calc((100% - 80px - 43px) / 2);
    text-align: center;

    padding: 0;
    margin: 0;

    opacity: 0;

    transition: opacity 0.3s ease;
}

.promo__type-small {
    font-size: 24px;
    line-height: 24px;

    top: calc((100% - 50px - 38px) / 2);
}

.promo__name {
    width: 100%;
    text-align: center;

    position: absolute;

    top: calc((100% - 50px) / 2);

    padding: 0;
    margin: 0;

    color: #FFF;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;

    opacity: 0;

    transition: opacity 0.3s ease;
}

.promo__name-small {
    font-size: 38px;
    line-height: 38px;

    top: calc((100% - 38px) / 2);
}

.promo__more {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;

    border-radius: 10px 0px 5px 0px;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(7px); 

    margin: 0;
    padding: 7px 22px;

    position: absolute;

    bottom: 0;
    right: 0;

    transition: background .5s linear;
}

.promo__more:hover {
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

@media screen and (max-width: 1320px) {
    .promo__container {
        width: calc(100% - 40px);
        margin: 0 20px;
    }
}

@media screen and (max-width: 900px) {
    .promo__container {
        max-width: 100%;
        grid-template-rows: repeat(2, 200px);
    }
}

@media screen and (max-width: 800px) {
    .promo__container {
        grid-template-columns: repeat(5, 1fr);
        grid-template-areas:
            "A A A B B"
            "A A A C C";
    }
}

@media screen and (max-width: 760px) {
    .promo__container {
        width: 760px;
        max-width: calc(100% - 40px);
    }
}

@media screen and (max-width: 700px) {
    .promo__container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 140px 140px 200px;
        grid-template-areas:
            "A A A A"
            "A A A A"
            "B B C C"
    }
}

@media screen and (max-width: 600px) {
    .promo__container {
        margin: 0 45px;
        max-width: calc(100% - 90px);
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 220px);
        grid-template-areas:
            "A"
            "B"
            "C"
    }
}

@media screen and (max-width: 500px) {
    .promo__container {
        max-width: calc(100% - 40px);
        margin: 0 20px;
    }
}
