.PopUpQuiz {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1050;
    height: 100vh;
    width: 100vw;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    justify-items: center;
    align-items: center;
}

.PopUpQuiz_opened {
    visibility: visible;
    opacity: 1;
}

.PopUpQuiz__container {
    width: 900px;
    background: #FFFFFF;
    border-radius: 5px;

    margin: 150px auto 0;
    position: relative;
}

.PopUpQuiz__container-first {
    padding: 50px 70px;
    width: calc(100% - 140px);
    height: 400px;
}

.PopUpQuiz__container-radio {
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);

    row-gap: 15px;
    column-gap: 20px;
}

.PopUpQuiz__title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.PopUpQuiz__lable {
    border: 1px solid #c2c2c2;
    padding: 12px;

    transition: border-color .3s linear;

    max-height: 44px;
    border-radius: 5px;
}

.PopUpQuiz__lable:hover {
    cursor: pointer;
}

.PopUpQuiz__radio {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: red;
}

.PopUpQuiz__radio {
    display: none;
}

.PopUpQuiz__input {
    padding: 0;
    margin: 0;

    border: none;

    width: 100%;
}


.PopUpQuiz__input::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: #000;
}

.PopUpQuiz__input:focus-visible {
    outline: none;
}

.PopUpQuiz__lable_active {
    border-color: #ED202A;
}

.PopUpQuiz__menu {
    position: absolute;

    bottom: 10px;
    right: 70px;

    display: flex;
    flex-direction: row;

    justify-content: end;
    align-items: center;

    margin-top: 30px;
    gap: 20px;
}

.PopUpQuiz__previosly {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.PopUpQuiz__previosly:hover {
    cursor: pointer;
}

.PopUpQuiz__more {
    color: #000;
    border-radius: 5px;
    border: 1px solid #8a8a8a;
    padding: 10px 20px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #f5f5f5;
}

.PopUpQuiz__more:disabled {
    opacity: 0.5;
}

.PopUpQuiz__more-active:hover {
    cursor: pointer;
}

.PopUpQuiz__container-squre {
    margin-top: 50px;
}

.PopUpQuiz__squre {
    padding: 0;
    margin: 0;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.PopUpQuiz__squre_range {
    width: 100%;
    margin-top: 30px;

    border-radius: 5px;
    accent-color: #ED202A;

    cursor: pointer;
}

.PopUpQuiz__squre_datalist {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.PopUpQuiz__squre_datalist-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.PopUpQuiz__container-price {
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);

    row-gap: 15px;
    column-gap: 20px;
}

.PopUpQuiz__container-time {
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);

    row-gap: 15px;
    column-gap: 20px;
}

.PopUpQuiz__container_form {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    height: 360px;
}

.PopUpQuiz__container-form_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;

    max-width: 50%;
}

.PopUpQuiz__container-form {
    display: flex;
    flex-direction: column;

    margin: 0 50px;
    gap: 20px;
}

.PopUpQuiz__container-form_link {
    text-decoration: none;
    color: #ED202A;
}

.PopUpQuiz__container-form_link:hover {
    cursor: pointer;
}

.PopUpQuiz__container-form_title {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.PopUpQuiz__container-form_input::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: #000;
}

.PopUpQuiz__container-form_input {
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px 0 5px 7px;
}

.PopUpQuiz__container-form_input:focus-visible {
    outline: #000;
}

.PopUpQuiz__container-form_checkbox {
    margin-right: 7px;
}

@media screen and (max-width: 950px) {
    .PopUpQuiz__container {
        width: 910px;
        max-width: calc(100% - 40px);
        margin: 150px 20px 0;
    }
}

@media screen and (max-width: 830px) {
    .PopUpQuiz__lable {
        font-size: 14px;
    }

    .PopUpQuiz__input::placeholder {
        font-size: 14px;
    }
}

@media screen and (max-width: 750px) {
    .PopUpQuiz__container-form_label {
        font-size: 13px;
    }

    .PopUpQuiz__container-form_checkbox {
        width: 10px;
        height: 10px;
    }
}

@media screen and (max-width: 700px) {
    .PopUpQuiz__container-form {
        margin: 0 20px 0 50px;
        gap: 20px;
    }
}

@media screen and (max-width: 650px) {
    .PopUpQuiz__container-first {
        padding: 50px 20px;
        width: calc(100% - 40px);
        height: 400px;
    }

    .PopUpQuiz__menu {
        right: 20px;
    }

    .PopUpQuiz__container {
        width: 910px;
        max-width: calc(100% - 20px);
        margin: 150px 10px 0;
    }
    
}

@media screen and (max-width: 550px) {
    .PopUpQuiz__container_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 360px;

        justify-content: initial;
        row-gap: 50px;
    }

    .PopUpQuiz__container-form_text {
        max-width: 100%;
        font-size: 22px;
        font-weight: 500;
    }

    .PopUpQuiz__container-form {
        margin: 0;
    }

    .PopUpQuiz__container-form_title {
        font-size: 26px;
    }
}

@media screen and (max-width: 450px) {
    .PopUpQuiz__container {
        margin: 40px 10px 0;
    }

    .PopUpQuiz__container-first {
        height: 490px;
    }

    .PopUpQuiz__title {
        font-size: 24px;
    }

    .PopUpQuiz__lable {
        font-size: 14px;
        padding: 10px;
    }

    .PopUpQuiz__container-time {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        margin-top: 30px;
    }

    .PopUpQuiz__more {
        padding: 8px 16px;
        font-size: 14px;
    }

    .PopUpQuiz__container-price {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        margin-top: 30px;
    }

    .PopUpQuiz__container-radio {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        margin-top: 30px;
    }
}


@media screen and (max-width: 360px) {
    .PopUpQuiz__title {
        font-size: 24px;
    }

    .PopUpQuiz__lable {
        font-size: 13px;
        padding: 8px;
    }

    .PopUpQuiz__input::placeholder {
        font-size: 13px;
    }

    .PopUpQuiz__squre {
        font-size: 20px;
    }
    
    .PopUpQuiz__squre_datalist-value {
        font-size: 14px;
    }

    .PopUpQuiz__container-form_text {
        font-size: 16px;
    } 


    .PopUpQuiz__container-form_label {
        font-size: 11px;
    }
}