.portfolioPreview {
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    column-gap: 20px;
    row-gap: 20px;
    margin: 0 0 0 20px;
}

@media screen and (max-width: 1320px) {
    .portfolioPreview {
        max-width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 750px) {
    .portfolioPreview {
        grid-template-columns: repeat(2, 1fr);

        column-gap: 15px;
        row-gap: 15px;
    }
}

@media screen and (max-width: 550px) {
    .portfolioPreview {
        grid-template-columns: 1fr;
    }
}

