.section__title {
    color: #000;
    font-family: Roboto;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.section__title span {
    color: #ED202A;
}

@media screen and (max-width: 800px) {
    .section__title {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .section__title {
        font-size: 45px;
    }
}

@media screen and (max-width: 430px) {
    .section__title {
        font-size: 30px;
    }
}

