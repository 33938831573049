.portfolioCard {
    position: relative;
    max-width: 400px;
    max-height: 600px;

    border-radius: 5px;
    overflow: hidden;
}

.portfolioCard__img {
    width: 400px;
    height: 600px;

    position: relative;

    transition: filter 0.3s ease;
    object-fit: cover;
}

.portfolioCard:hover {
    .portfolioCard__img {
        filter: brightness(0.5) blur(1.5px);
    }

    .portfolioCard__type,
    .portfolioCard__name,
    .portfolioPreview__address {
        opacity: 1;
    }
}


.portfolioCard__type {
    width: 100%;

    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    position: absolute;

    top: 264px;
    text-align: center;

    padding: 0;
    margin: 0;

    opacity: 0;

    transition: opacity 0.3s ease;
}

.portfolioCard__name {
    width: 100%;
    text-align: center;

    position: absolute;

    top: 303px;

    padding: 0;
    margin: 0;

    color: #FFF;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    opacity: 0;

    transition: opacity 0.3s ease;
}

.portfolioPreview__address {
    width: 100%;
    text-align: center;

    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    position: absolute;

    padding: 0;
    margin: 0;

    opacity: 0;

    transition: opacity 0.3s ease;
}

.portfolioPreview__address_city {
    top: 529px;
}

.portfolioPreview__address_tc {
    top: 564px;
}

@media screen and (max-width: 1320px) {
    .portfolio {
        width: clac(100% - 60px);
        margin: 135px 30px 0;
    }

    .portfolio__type {
        max-width: calc(100% - 117px);
    }
}

@media screen and (max-width: 900px) {
    .portfolioCard__info {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.4);

        backdrop-filter: blur(1.5px);

        width: 100%;
        height: 200px;

        position: absolute;
        bottom: 0;
    }

    .portfolioCard__type,
    .portfolioCard__name,
    .portfolioPreview__address {
        opacity: 1;
    }

    .portfolioCard__type {  
        top: calc(((200px - 32px) / 2) - 26px);

        font-size: 20px;
    }

    .portfolioCard__name {   
        top: calc((200px - 32px) / 2);

        font-size: 32px;
    }

    .portfolioPreview__address_city {
        top: 147px;
    }

    .portfolioPreview__address {
        font-size: 16px;
    }

    .portfolioPreview__address_tc {
        top: 170px;
    }
}

@media screen and (max-width: 550px) {
    .portfolioCard {
        max-height: 450px;
        width: 100%;

        max-width: 100%;
    }

    .portfolioCard__img {
        max-height: 450px;
        width: 100%;

        max-width: 100%;

        object-fit: cover;
    }

    .portfolioCard__info {
        height: 150px;
    }

    .portfolioCard__type {  
        top: calc(((150px - 30px) / 2) - 30px);

        font-size: 18px;
    }

    .portfolioCard__name {   
        top: calc(((150px - 30px) / 2) - 10px);

        font-size: 30px;
    }

    .portfolioPreview__address {
        font-size: 16px;
    }

    .portfolioPreview__address_city {
        top: calc(150px - 24px - 20px);
    }

    .portfolioPreview__address_tc {
        top: calc(150px - 24px);
    }
}