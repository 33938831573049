.footer__container {
    width: calc(100% - 200px);
    margin: 0 auto;
    padding: 40px 100px 40px;

}

.footer {
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.footer__logo {
    max-width: 238px;
    max-height: 51px;
    padding-right: 20px;
}

.footer__info {
    width: 100%;

    border-bottom: 2px solid #ED202A;
}

.footer__nav {
    padding: 0;
    margin: 15px auto 0;
    display: flex;
    flex-direction: row;
    column-gap: 40px;

    justify-content: end;
}

.footer__nav-link {
    transition: color .3s linear;

    padding: 0;
    margin: 0;

    color: #000;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    list-style: none;
}

.footer__nav-link:hover {
    cursor: pointer;
    color: #6d6d6d;
}

.footer__nav-link-text {
    padding: 0;
    margin: 0;

    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-decoration: none;

    transition: color .3s linear;
}

.footer__nav-link-text:hover {
    cursor: pointer;
    color: #6d6d6d;
}

@media screen and (max-width: 1320px) {
    .footer__container {
        width: calc(100% - 40px);
        padding: 40px 20px 20px;
    }

}

@media screen and (max-width: 1100px) {
    .footer__container {
        padding: 20px 20px 20px;
    }

}

@media screen and (max-width: 1000px) {
    .footer__nav {
        column-gap: 25px;
    }

    .footer__nav-link-text {
        font-size: 13px;
    }

}

@media screen and (max-width: 800px) {
    .footer__logo {
        max-width: 200px;
        max-height: 42px;
    }

    .footer__nav {
        column-gap: 20px;
        margin: 12px auto 0;
    }

}

@media screen and (max-width: 600px) {
    .footer {
        justify-content: center;
    }

    .footer__logo {
        max-width: 200px;
        max-height: 42px;
    }

    .footer__info {
        width: 0;
    }

    .footer__nav {
        visibility: hidden;
        opacity: 0;
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .footer__logo {
        max-width: 150px;
        max-height: 32px;

        padding-top: 8px;
    }

    .footer__nav-links {
        column-gap: 30px;
    }
}

@media screen and (max-width: 450px) {
    .footer__logo {
        max-width: 150px;
        max-height: 32px;

        padding-top: 8px;
    }

    .footer__nav-links {
        column-gap: 30px;
    }
}

@media screen and (max-width: 450px) {
    .footer {
        display: flex;
        flex-direction: column;

        align-items: center;
    }

    .nav__link {
        font-size: 16px;
    }
}