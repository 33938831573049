.politics__section {
    max-width: 1280px;
    margin: 65px auto;
}

.politics_title {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;

    margin: 0 0 20px;
    padding: 0;
}

.politics {
    max-width: 1220px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.politics_text {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;

    margin: 0;
    padding: 0;

    text-align: justify; 
}

.politics_name {
    font-size: 20px;
    font-weight: 400;

    margin: 10px 0 0;
}

.politics_point {
    padding-left: 30px;
}

.politics_point-list {
    font-size: 17px;
    padding-left: 60px;
}

@media screen and (max-width: 1320px) {
    .politics__section {
        margin: 65px 50px;
    }
}

@media screen and (max-width: 1100px) {
    .politics_text {
        font-size: 16px;
    }

    .politics_name {
        font-size: 18px;
    }

    .politics_point-list {
        font-size: 15px;
    }
}

@media screen and (max-width: 750px) {
    .politics_title {
        font-size: 25px;
        margin: 0 0 20px;
    }

    .politics_text {
        font-size: 15px;
    }

    .politics_name {
        font-size: 17px;
    }

    .politics_point-list {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .politics__section {
        margin: 65px 20px;
    }
}

@media screen and (max-width: 400px) {
    .politics_text {
        font-size: 13px;
    }

    .politics_name {
        font-size: 15px;
    }

    .politics_point {
        padding-left: 15px;
    }

    .politics_point-list {
        font-size: 12px;
        padding-left: 20px;
    }
}

@media screen and (max-width: 400px) {
    .politics_text {
        padding: 0;
    }

    .politics_text {
        font-size: 12px;
    }

    .politics_name {
        font-size: 14px;
    }

    .politics_point-list {
        font-size: 11px;
    }

    .politics_title {
        font-size: 20px;
        margin: 0 0 15px;
    }

    .politics__section {
        margin: 45px 20px;
    }
}