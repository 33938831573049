.about {
    max-width: 1220px;
    margin: 65px auto 0;
}

.about__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 70px 0 0 117px;
}

.about__info {
    max-width: 570px;

    display: flex;
    flex-direction: column;

    gap: 40px;
}

.about__info_text {
    color: #000;
    font-size: 27px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.about__info_text-bold {
    font-weight: 400;
    font-size: 29px;
}

.about__info_name {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.about__info_name:first-of-type {
    color: #ED202A;
}

.about__person {
    margin-top: -15px;
    max-width: 500px;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.about__person_img {
    border-radius: 0 2px 0 2px;
    max-width: 300px;

    width: 100%;
    max-height: 331px;
    margin-left: 5px;
}

.about__person_text {
    width: calc(100% - 25px);
    position: absolute;
    bottom: 5px;
    left: 5px;

    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 10px;
    margin: 0;

    background: linear-gradient(to top, #000, transparent);
    backdrop-filter: blur(10px); 
}

.about__person_img-container:first-of-type {
    border-radius: 2px;
    margin-left: 90px;

    background-color: #ED202A;
    width: 305px;
    height: 336px;
}

.about__person_img-container {
    position: relative;
    border-radius: 2px;

    background-color: #d6d6d6;
    width: 305px;
    height: 305px;
}

.about__statistics {
    margin: 80px auto 0;
    max-width: 850px;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    gap: 45px;
}

.about__statistics_number {
    color: #ED202A;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 0 0 5px;
    margin: 0;
}

.about__statistics_text {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.aboutUs__container {
    margin-top: 120px;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.education__container_img {
    display: flex;
    flex-direction: column; 
    row-gap: 30px; 
}

.education__container {
    max-width: 500px;
    display: flex;
    flex-direction: column; 
    row-gap: 30px;   
}

.education__info {
    margin: 0;
    padding: 0 0 15px;
    text-align: end;
}

.education__info:last-of-type {
    padding: 0 28px 15px 0;
}


.education__info_name {
    color: #000;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.education__info_name:first-of-type {
    color: #ED202A;
    padding-right: 15px;
}

.education__info_img {
    max-width: 300px;
    max-height: 120px;
    object-fit: contain;
}

.partners__container {
    display: flex;
    flex-direction: column; 
    row-gap: 30px;   
}

.partners__container_img {
    display: grid;
    grid-template-columns: 200px 200px;
    column-gap: 40px;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
}

.partners__info_img {
    max-width: 200px;
    max-height: 120px;
    object-fit: contain;
}

.partners__info_img-mid {
    max-height: 43px;
}


@media screen and (max-width: 1320px) {
    .about {
        width: calc(100% - 60px);
        margin: 105px 30px 0 30px;
    }

    .about__container {
        width: calc(100% - 60px - 117px);
    }

    .aboutUs__container {
        width: calc(100% - 60px);
        margin: 120px auto 0;
    }
}

@media screen and (max-width: 1250px) {
    .about__container {
        width: calc(100% - 60px - 60px);
        margin: 70px 0 0 60px;
    }

    .about__statistics {
        padding-right: 60px;
    }
}

@media screen and (max-width: 1100px) {
    .about__container {
        width: calc(100% - 60px - 60px);
        margin: 70px 0 0 60px;
    }

    .about__info_name {
        font-size: 24px;
    }

    .about__info {
        max-width: 400px;  
        gap: 55px;
    }

    .about__info_text {
        font-size: 20px;
    }

    .about__statistics {
        max-width: 600px;
        gap: 35px;
    }

    .about__statistics_number {
        font-size: 32px;
    }

    .about__statistics_text {
        font-size: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .about__person_img-container {
        max-width: 250px;
    }

    .about__person_img {
        max-width: 245px;
    }

    .education__container {
        row-gap: 20px
    }

    .education__container_img {
        row-gap: 20px 
    }

    .education__info_img {
        max-width: 200px;
    }

    .education__info_name {
        font-size: 28px;
    }

    .education__info_name:first-of-type {
        padding-right: 10px;
    }

    .partners__container {
        row-gap: 20px;
    }

    .partners__container_img {
        column-gap: 20px;
        row-gap: 20px
    }

    .partners__info_img {
        max-height: 100px;
    }
}

@media screen and (max-width: 950px) {
    .about {
        margin-top: 100px;
    }

    .about__container {
        width: calc(100% - 50px);
        margin: 60px 0 0 50px;
    }

    .about__info_name {
        font-size: 22px;
    }

    .about__info {
        max-width: 350px;  
        gap: 45px;
    }

    .about__info_text {
        font-size: 18px;
    }

    .about__person_img-container {
        max-height: 300px;
    }

    .about__person_img-container:first-of-type {
        margin-left: 50px;
    }

    .about__statistics_number {
        font-size: 30px;
    }

    .about__statistics_text {
        font-size: 18px;
    }
}

@media screen and (max-width: 950px) {
    .partners__container_img {
        grid-template-columns: 150px 150px;
    }

    .partners__info_img {
        max-width: 150px;
    }

    .education__info:last-of-type {
        padding: 0 0 15px 0;
    }
}

@media screen and (max-width: 800px) {
    .about__info {
        max-width: 300px;
    }

    .about__info_text {
        font-size: 16px;
    }

    .about__info_name {
        font-size: 20px;
    }

    .about__info_text-bold {
        font-size: 18px;
    }

    .about__person_img-container:first-of-type {
        margin-left: 0;
    }

    .about__person{
        margin-top: -30px;
    }

    .about__statistics {
        padding-right: 0;
    }

    .about__person_img-container {
        max-width: 220px;
        max-height: 240px;
    }

    .about__person_img {
        max-width: 215px;
        max-height: 235px;
    }

    .about__person_text {
        font-size: 12px;
    }

    .about__person {
        margin-top: 20px;
    }

    .about__statistics_number {
        font-size: 27px;
    }

    .about__statistics_text {
        font-size: 16px;
    }

    .about__statistics {
        max-width: 500px;
        gap: 35px;
    }

    .education__info_img {
        max-width: 150px;
    }

    .partners__container_img {
        grid-template-columns: 120px 120px;
        column-gap: 10px;
    }

    .partners__info_img {
        max-width: 120px;
        max-height: 80px;
    }

    .partners__container {
        align-items: end;
    }

    .aboutUs__container {
        margin-top: 90px;
    }
}

@media screen and (max-width: 750px) {
    .education__info_name {
        font-size: 24px;
    }

    .education__info:last-of-type  {
        padding: 0;
    }
}

@media screen and (max-width: 700px) {
    .about__container {
        width: calc(100% - 30px);
        margin: 60px 0 0 30px;
    }
}

@media screen and (max-width: 650px) {
    .aboutUs__container {
        flex-direction: column;
    }

    .education__info {
        text-align: center;
    }

    .education__container {
        row-gap: 30px;
    }

    .partners__container {
        align-items: center;
        margin-top: 60px;
        row-gap: 30px;
    }

    .education__container_img {
        display: grid;
        grid-template-columns: 200px 200px;

        column-gap: 20px;
        row-gap: 20px;

        max-width: 420px;

        margin: 20px auto 0;
    }

    .education__info_img {
        width: 200px;
        max-width: 200px;
    }

    .partners__container_img {
        grid-template-columns: 200px 200px;
        column-gap: 20px;
    }

    .partners__info_img {
        max-width: 200px;
        max-height: 120px;
    }

    .education__info_name {
        font-size: 28px;
    }

    .education__info_name:first-of-type {
        padding-right: 15px;
    }
}

@media screen and (max-width: 630px) {
    .about__info_text {
        font-size: 14px;
    }

    .about__info_name {
        font-size: 18px;
    }

    .about__info {
        max-width: 270px;
    }

    .about__person {
        margin-top: -10px;
    }
}

@media screen and (max-width: 600px) {
    .about {
        width: calc(100% - 40px);
        margin: 80px 20px 0;
    }

    .about__container {
        width: calc(100% - 50px);
        margin: 45px auto 0;

        flex-direction: column;
        align-items: center;
    }

    .about__info_name {
        font-size: 22px;
    }

    .about__info_text {
        font-size: 16px;
    }

    .about__info_text-bold {
        font-size: 20px;
    }

    .about__info {
        max-width: 100%;
        gap: 25px;
    }

    .about__person {
        flex-direction: row;

        margin-top: 40px;
    }

    .about__person_img-container {
        max-width: 250px;
        max-height: 270px;

        width: 245px;
    }

    .about__person_img-container:first-of-type {
        width: 245px;
    }

    .about__person_img {
        max-width: 240px;
        width: 240px;
    }

    .about__person_text {
        font-size: 12px;
    }

    .about__person {
        margin-top: 20px;
    }

    .about__statistics {
        margin-top: 50px;
    }

    .about__statistics_number {
        font-size: 28px;
    }

    .about__statistics_text {
        font-size: 16px;
    } 

    .aboutUs__container {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 550px) {
    .about__person {
        flex-direction: column;

        margin-top: 40px;
    }

    .about__statistics {
        max-width: 450px;
    }

    .about__statistics_text {
        font-size: 14px;
    } 

    .education__container {
        row-gap: 10px;
    }

    .education__container_img {
        grid-template-columns: 150px 150px;
    }

    .education__info_img {
        width: 150px;
    }

    .partners__container_img {
        grid-template-columns: 150px 150px;
    }

    .partners__info_img {
        width: 150px;
    }
}

@media screen and (max-width: 500px) {
    .about {
        margin: 70px 20px 0;
    }

    .about__info_text-bold {
        font-size: 18px;
    }

    .about__container {
        margin: 35px auto 0;
    }

    .about__statistics {
        margin-top: 50px;
        max-width: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }

    .about__statistics_number {
        font-size: 30px;
    }

    .about__statistics_text {
        font-size: 16px;
    } 
}

@media screen and (max-width: 450px) {
    .about__container {
        width: calc(100% - 10px);
    }

    .about__info{
        gap: 25px;
    }

    .about__info_text {
        font-size: 16px;
    }

    .about__info_name {
        font-size: 20px;
    }

    .about__statistics {
        margin-top: 40px;
        gap: 25px;
    }

    .education__info_name {
        font-size: 22px; 
    }

    .education__info_name:first-of-type {
        padding-right: 7px;
    }

    .education__container_img {
        grid-template-columns: 110px 110px;
    }

    .education__info_img {
        width: 110px;
    }

    .partners__container_img {
        grid-template-columns: 110px 110px;
    }

    .partners__info_img {
        max-width: 110px;
        max-height: 90px;
    }
}

