.header {
    max-width: 100%;
    margin: 20px auto 0;
    padding: 22px 100px 40px;

    background-color: white;

    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.sticky {
    position: sticky;
    top: 0;

    z-index: 5;
}


.header__container {
    width: 100%;
    display: grid;
    grid-template-columns: .5fr 1fr .5fr;
}

.header__logo_container {
    display: flex;
    flex-direction: row;
}

.header__logo {
    width: 238px;
    height: 51px;
    flex-shrink: 0;
    padding-right: 20px;
}

.header__container_small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__logo_small {
    width: 200px;
    height: 42px;
}

.red__line {
    width: 100%;
    border-bottom: 2px solid #ED202A;
}

.header__nav-links {
    width: calc(100% - 10px);
    padding-left: 10px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    border-bottom: 2px solid #ED202A;
}

.header__nav-link {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-decoration: none;

    list-style: none;

    padding: 16px 0 0;
    margin: 0;

    transition: color .3s linear;
}

.nav__link {
    font-size: 21px;
}

.header__nav-link:hover {
    cursor: pointer;
    color: #6d6d6d;
}

.header__phone {
    text-align: end;

    color: #000;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 16px 0 0;
    margin: 0;

    border-bottom: 2px solid #ED202A;
}

.header__hamburger-menu {
    width: 28px;
	height: 23px;
	position: relative;
	z-index:2;

    margin-top: 18px;
    margin-right: 8px;
}

.header__hamburger-menu:hover {
    cursor: pointer;
    opacity: .8;
}

.header__hamburger-menu span {
	width: 28px;
	height: 3px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #000000;
	transition: all 0.5s;
}

.header__hamburger-menu span:nth-of-type(2) {
	top: calc(50% - 7px);
}
.header__hamburger-menu span:nth-of-type(3) {
	top: calc(50% + 7px);
}

.header__hamburger-menu_active {
    margin-top: 18px;
}

.header__hamburger-menu_active:hover {
    cursor: pointer;
    opacity: .8;
}

.header__hamburger-menu_active span:nth-of-type(1) {
    display: none;
}

.header__hamburger-menu_active span:nth-of-type(2) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(45deg);  
}

.header__hamburger-menu_active span:nth-of-type(3) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(-45deg); 
}

@media screen and (max-width: 1320px) {
    .header {
        width: calc(100% - 40px);
        padding: 42px 20px 60px;
    }
}

@media screen and (max-width: 1100px) {
    .header {
        padding: 30px 20px 60px;
    }

    .nav__link {
        font-size: 16px;
    }

    .header__nav-links {
        width: calc(100% - 30px);
        padding: 0 10px 0 20px;
    }

    .header__phone {
        font-size: 16px;
        padding: 24px 0 0;
    }

    .header__nav-link {
        padding: 24px 0 0;
    }
}

@media screen and (max-width: 1000px) {
    .header__logo {
        width: 180px;
        height: 48px;
    }

    .header {
        padding: 30px 20px 45px;
    }
}

@media screen and (max-width: 850px) {
    .header__logo {
        width: 150px;
        height: 36px;
    }

    .nav__link {
        font-size: 14px;
    }

    .header__phone {
        font-size: 14px;
        padding: 16px 0 0;
    }

    .header__nav-link {
        padding: 12px 0 0;
    }
}

@media screen and (max-width: 760px) {
    .header {
        margin: 0 auto;
    }

    .header__nav-link{
        padding-top: 13px;
    }

    .nav__link {
        font-size: 14px;
    }

    .header__phone {
        font-size: 16px;
        padding-top: 15px;
    }

    .header__logo {
        width: 120px;
        height: 38px;
    }
}


