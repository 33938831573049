.quiz__section {
    max-width: 100%;
    margin: 65px auto;
    padding: 65px 0;

    background-color: #eeeeee;
}

.quiz {
    max-width: 1220px;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
}

.quiz__img {
    width: calc(100% / 2 + 150px);
    border-radius: 5px;
}

.quiz__info_text {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    padding: 0;
    margin: 0;
}

.quiz__info {
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 50px;

    padding-left: 40px;

    margin-top: -30px;

    position: relative;
}

.quiz__info_button {
    width: 192px;
    border: 2px solid #ED202A;
    border-radius: 5px;
    background: transparent;

    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 12px;

    transition: .3s;

    text-decoration: none;
    text-transform: uppercase;
}

.quiz__info_button:hover {
    cursor: pointer;

    box-shadow: inset 0px 0px 0px 1px #ED202A;
}

@media screen and (max-width: 1320px) {
    .quiz {
        width: calc(100% - 60px);
        margin: 0 30px 0;
    }
}

@media screen and (max-width: 1100px) {
    .quiz__info_text {
        font-size: 28px;
    }

    .quiz__info {
        margin-top: -5px;
    }
}

@media screen and (max-width: 900px) {
    .quiz__info_text {
        font-size: 26px;
    }

    .quiz__info {
        gap: 30px;
    }

    .quiz__info_button {
        width: 170px;

        font-size: 20px;

        padding: 10px;
    }
}

@media screen and (max-width: 800px) {
    .quiz__img {
        width: calc(100% / 2 + 70px);
        height: 350px;
        object-fit: cover;
        border-radius: 5px;
    }

    .quiz__info {
        gap: 30px;
    }
}

@media screen and (max-width: 750px) {
    .quiz__container {
        width: 100%;
        max-height: 470px;

        position: relative;
    }

    .quiz__img {
        width: 100%;
        height: 470px;
        object-fit: cover;
        border-radius: 5px;
    }

    .quiz__img_container:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        border-radius: 5px;
        backdrop-filter: blur(3px);
    }

    .quiz__info_text {
        max-width: 550px;
        position: absolute;

        top: calc((100% - (76px + 44px)) / 2);
        left: calc((100% - 550px) / 2);

        font-size: 32px;
        font-weight: 500;

        text-align: center;

        color: #fff;
    }

    .quiz__info_button {
        color: #fff;
        position: absolute;

        top: calc(100% - (100% - (76px + 14px)) / 2);
        left: calc((100% - 170px) / 2);
    }
}

@media screen and (max-width: 650px) {
    .quiz__info_button {
        width: 140px;
        font-size: 16px;
        padding: 10px;

        left: calc((100% - 140px) / 2);
    }

    .quiz {
        width: calc(100% - 40px);
        margin: 0 20px 0;
    }
}

@media screen and (max-width: 600px) {
    .quiz__info_text {
        max-width: 450px;

        top: calc((100% - (114px + 40px)) / 2);
        left: calc((100% - 450px) / 2);
    }

    .quiz__info_button {
        top: calc(100% - (100% - (76px + 54px)) / 2);
        border: 1px solid #ED202A;
    }
}

@media screen and (max-width: 500px) {
    .quiz__info_text {
        max-width: 350px;
        font-size: 28px;

        top: calc((100% - (114px + 25px)) / 2);
        left: calc((100% - 350px) / 2);
    }

    .quiz__info_button {
        top: calc(100% - (100% - (76px + 30px)) / 2);
        left: calc((100% - 130px) / 2);

        width: 130px;
        font-size: 14px;
        font-weight: 400;
    }
}

@media screen and (max-width: 385px) {
    .quiz__info_text {
        max-width: 300px;
        font-size: 24px;

        top: calc((100% - (87px + 35px)) / 2);
        left: calc((100% - 300px) / 2);
    }
}

@media screen and (max-width: 340px) {
    .quiz__info_text {
        max-width: 260px;
        font-size: 22px;

        top: calc((100% - (81px + 30px)) / 2);
        left: calc((100% - 260px) / 2);
    }

    .quiz__info_button {
        top: calc(100% - (100% - (76px + 30px)) / 2);
        left: calc((100% - 110px) / 2);

        width: 110px;
        font-size: 12px;
        font-weight: 400;
    }
}