.price__section {
    max-width: 100%;
    margin: 65px auto;
    padding: 65px 0;

    background-color: #f5f5f5;
}

.price {
    max-width: 1280px;
    width: calc(100% - 60px);
    margin: 0 auto;
}

.price__container {
    margin: 70px 0 0 20px;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.price__card {
    max-width: calc((100% - 160px) / 3);

    border: 1px solid #000;
    border-radius: 5px;

    padding: 10px;

    height: 350px;

    position: relative;

    background-color: #fafafa;
}

.price__card-title {
    padding: 0;
    margin: 20px 0 40px;

    text-align: center;

    color: #000;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.price__card-about {
    display: flex;
    flex-direction: column;

    row-gap: 20px;

    max-width: 320px;
}

.price__card-about_text {
    padding: 0;
    margin: 0;

    text-align: center;

    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.price__card-price {
    position: absolute;

    bottom: 0;

    color: #ED202A;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    width: calc(100% - 20px);
    text-align: center;

    margin: 0 0 15px;
    bottom: 0;
}

.price__info {
    padding: 0;
    margin: 50px 0 0;

    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.price__info-button {
    padding: 0;
    margin: 0;

    color: #ED202A;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border: none;
    background-color: transparent;
    transition: color .3s linear;
}

.price__info-button:hover {
    cursor: pointer;
    color: #f18f94;
}


@media screen and (max-width: 1320px) {
    .price {
        width: calc(100% - 60px);
        margin: 0 30px 0;
    }

    .price__container {
        margin: 70px 0 0 0;
    }
}

@media screen and (max-width: 1100px) {
    .price__card {   
        padding: 10px 20px;
    }

    .price__container {
        column-gap: 35px;
    }
}

@media screen and (max-width: 950px) {
    .price__card-title {
        margin: 20px 0 35px;
        font-size: 25px;
    }

    .price__card-about_text {
        font-size: 16px;
    }
}

@media screen and (max-width: 850px) {
    .price__card {   
        padding: 10px 10px;
    }

    .price__card-title {
        margin: 20px 0 30px;
        font-size: 24px;
    }

    .price__card-about {
        row-gap: 10px;
        padding: 0 5px;
    }
}

@media screen and (max-width: 750px) {
    .price__container {
        flex-direction: column;
        row-gap: 20px;
    }

    .price__card {
        display: flex;
        flex-direction: row;

        max-width: 100%;
        height: 130px;

        align-items: center;

        justify-content: space-between;
        padding: 10px 15px;

        column-gap: 20px;
    }

    .price__card-title {
        margin: 0;
        font-size: 20px;
        width: 100%;

        max-width: 175px;

        text-align: start;
    }

    .price__card-about {
        row-gap: 10px;
        padding: 0;

        width: 100%;
        max-width: 270px;
    }

    .price__card-about_text {
        font-size: 14px;
    }

    .price__card-price {
        position: static;

        width: 100%;
        max-width: 150px;

        margin: 0;

        text-align: end;
    }
}

@media screen and (max-width: 700px) {
    .price__card {
        height: 110px;
    }

    .price__card-title {
        font-size: 18px;

        max-width: 160px;
    }

    .price__card-about {
        max-width: 200px;
    }

    .price__card-about_text {
        font-size: 12px;
    }

    .price__card-price {
        font-size: 17px;
        max-width: 125px;
    }

    .price__info {
        font-size: 18px;
    }

    .price__info-button {
        font-size: 18px;
    }
}

@media screen and (max-width: 650px) {
    .price__card-title {
        font-size: 16px;

        max-width: 120px;
    }

    .price__card-price {
        font-size: 15px;
        max-width: 110px;
    }

    .price__info {
        font-size: 20px;
    }
    
    .price__info-button {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .price__card {
        flex-direction: column;

        max-width: 100%;
        height: 250px;

        padding: 20px 15px 10px;
    }

    .price__card-title {
        font-size: 27px;

        max-width: 100%;

        text-align: center;
    }

    .price__card-about {
        max-width: 90%;
    }

    .price__card-about_text {
        font-size: 17px;
    }

    .price__card-price {
        font-size: 18px;
        max-width: 100%;

        text-align: center;
    }

    .price__info {
        font-size: 16px;
    }
    
    .price__info-button {
        font-size: 16px;
    }

    .price__info {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .price__card {
        height: 300px;
    }

    .price__container {
        margin-top: 40px;
    }

    .price__card-about_text {
        font-size: 15px;
    }
}

