@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.contacts {
    max-width: 1220px;
    margin: 65px auto 0;
}

.contacts__container {
    max-width: calc(100% - 20px);
    margin: 70px 20px 0;

    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.contacts__form {
    max-width: 480px;

    display: flex;
    flex-direction: column;

    row-gap: 51px;
}

.contacts__form-input {
    width: 480px;

    border: none;
    border-bottom: 1px solid #000;

    padding: 0 0 5px 11px;
}

.contacts__form-input:focus-visible {
    outline: none;
}

.contacts__form-input::placeholder {
    color: #646464;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.contacts__form_checkbox {
    margin-right: 8px;
}

.contacts__form-button {
    background: transparent;
    border: 2px solid #ED202A;
    border-radius: 5px;

    color: #000;

    padding: 19px 17px;

    max-width: 190px;

    margin: 9px auto 0;

    text-decoration: none;
    text-transform: uppercase;
}

.contacts__form-button:hover {
    cursor: pointer;

    box-shadow: inset 0px 0px 0px 1px #ED202A;
}

.contacts__form-error {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    color: #ED202A;

    padding: 0;
    margin: -40px 0 0 -5px;
    text-align: center;

    visibility: hidden;
    opacity: 0;
}

.contacts__form-error_active {
    visibility: visible;
    opacity: 1;
}

.contacts__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    row-gap: 25px;

    align-items: flex-end;
}

.contacts__list-contact {
    text-decoration: none;
    max-width: 285px;
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;

    padding: 0;
    margin: 0;

    text-align: end;
}

.contacts__list-contact_hover {
    color: #000;
    position: relative;
    transition: color .3s linear;
}

.contacts__list-contact_hover:hover {
    cursor: pointer;
    color: #6d6d6d;

    .contacts__list-contact_tg::before {
        animation: shake 2s cubic-bezier(.36, .07, .19, .97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}


.contacts__list-contact_tg::before {
    position: absolute;
    content: '';
    background-image: url('../../images/icons/tg.svg');
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    top: 1px;
    left: -30px;
}


@media screen and (max-width: 1320px) {
    .contacts {
        max-width: 100%;
        width: calc(100% - 60px);
        margin: 105px 30px 0;
    }

    .contacts__container {
        width: calc(100% - 60px);
        margin: 105px 30px 0;
    }
}

@media screen and (max-width: 1000px) {
    .contacts__list-contact {
        font-size: 17px;
        max-width: 220px;
    }

    .contacts__form-input {
        width: 440px;
    }
}

@media screen and (max-width: 900px) {
    .contacts__container {
        margin: 55px 0 0 50px;
    }

    .contacts__form {
        width: 400px;
        row-gap: 47px;
    }

    .contacts__form-input {
        padding: 0 0 4px 11px;
        width: 350px;
    }

    .contacts__list-contact {
        font-size: 15px;
        max-width: 194px;
    }

    .contacts__list {
        row-gap: 15px;
    }
}

@media screen and (max-width: 850px) {
    .contacts__list-contact_tg::before {
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        top: 0;
        left: -20px;
    }
}

@media screen and (max-width: 800px) {
    .contacts__container {
        margin: 55px 0 0 50px;
    }

    .contacts__form {
        width: 300px;
        row-gap: 47px;
    }

    .contacts__form-input {
        width: 300px;
        padding: 0 0 4px 11px;
    }

    .contacts__form-input::placeholder {
        font-size: 14px;
    }

    .contacts__list-contact {
        font-size: 14px;
        line-height: 20px;
    }

    .contacts__form-button {
        padding: 12px 14px;

        font-size: 14px;
    }
}

@media screen and (max-width: 700px) {
    .contacts__container {
        flex-direction: column;

        margin: 55px auto 0;

        align-items: center;
    }

    .contacts__form {
        width: 400px;
        row-gap: 47px;
        margin: 0 auto;
    }

    .contacts__form-input {
        width: 389px;
    }

    .contacts__list {
        width: 420px;
        display: grid;
        grid-template-columns: 195px 195px;

        align-items: start;

        margin: 35px 0 0;
        column-gap: 30px;

        flex-wrap: wrap;
    }

    .contacts__list-contact {
        text-align: center;
        width: 100%;
    }

    .contacts__list-contact_tg::before {
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        top: 0;
        left: 40px;
    }
}

@media screen and (max-width: 600px) {
    .contacts__form-button {
        border: 1px solid #ED202A;
    }
}

@media screen and (max-width: 550px) {
    .contacts {
        margin-top: 80px;
    }

    .contacts__container {
        flex-direction: column;

        margin: 55px auto 0;
    }

    .contacts__form {
        width: 100%;
        row-gap: 35px;
        margin: 0 auto;
    }

    .contacts__form-input {
        max-width: calc(100% - 11px);
    }

    .contacts__list {
        row-gap: 10px;
    }

    .contacts__list-contact {
        font-size: 12px;
        line-height: 19px;
    }

    .contacts__form_label {
        font-size: 14px;
    }

    .contacts__form_checkbox {
        width: 11px;
        height: 11px;
    }

    .contacts__list-contact_tg::before {
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        top: 0;
        left: 50px;
    }
}

@media screen and (max-width: 450px) {
    .contacts__form-input::placeholder {
        font-size: 14px;
    }

    .contacts__list {
        width: 100%;
    }

    .contacts__list {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 35px 0 0;
        row-gap: 15px;
    }

    .contacts__list-contact {
        font-size: 14px;
        line-height: 19px;
    }
}

@media screen and (max-width: 350px) {
    .contacts__container {
        width: calc(100% - 60px);
    }

    .contacts__form-input::placeholder {
        font-size: 13px;
    }

    .contacts__form-input {
        padding-left: 3;
    }

    .contacts__form-button {
        padding: 10px 10px;
        font-size: 12px;
        margin-top: 0;
    }

    .contacts__form_label {
        font-size: 12px;
    }

    .contacts__form_checkbox {
        width: 10px;
        height: 10px;
    }
}