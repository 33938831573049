.requisites {
    max-width: 1280px;
    margin: 65px auto 120px;
}

.requisites__table {
    max-width: 1220px;
    margin: 40px auto;
    border-collapse: collapse;
}

.requisites__table-text {
    padding: 8px 30px;
    border: 1px solid #ccc;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;

    margin: 0;
}

.requisites__table-text_name {
    font-size: 18px;
    font-weight: 400;
}

@media screen and (max-width: 1320px) {
    .requisites {
        max-width: 1280px;
        margin: 65px 20px 120px;
    }
}

@media screen and (max-width: 1000px) {  
    .requisites__table-text_name {
        font-size: 14px;
    }

    .requisites__table-text {
        padding: 8px 30px 8px 20px;
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    .requisites__table-text_name {
        font-size: 12px;
    }

    .requisites__table-text {
        padding: 8px 30px 8px 20px;
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .requisites__table-text_name {
        font-size: 10px;
    }

    .requisites__table-text {
        padding: 8px 30px 8px 10px;
        font-size: 13px;
    }
}

@media screen and (max-width: 380px) {
    .requisites__table-text_name {
        font-size: 9px;
    }

    .requisites__table-text {
        padding: 8px 20px 8px 10px;
        font-size: 11px;
    }
}