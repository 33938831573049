.project {
    max-width: 1240px;
    margin: 0 auto;
}

.project__return {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    margin: 30px 0 0;

    border: none;
    background-color: transparent;

    transition: color .3s linear;
}

.project__return:hover {
    cursor: pointer;
    color: #6d6d6d;
}

.project__cover {
    margin: 15px auto 77px auto;
    max-width: 1240px;
    max-height: 830px;

    position: relative;
}

.project__cover-img {
    width: 100%;
    max-height: 830px;

    filter: brightness(0.5);
    object-fit: cover;

    border-radius: 5px;
}

.project__cover-text {
    position: absolute;

    color: #FFF;
    font-size: 70px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;

    text-align: center;
    width: 100%;
}

.project__cover-type {
    top: calc((100% - 70px) / 2 - 80px);
}

.project__cover-name {
    top: calc((100% - 90px) / 2);

    font-size: 90px;
    font-weight: 700;
}

.project__cover-square {
    bottom: 25px;

    font-size: 24px;
    font-weight: 300;
}

.project__info {
    display: flex;
    flex-direction: row;

    column-gap: 35px;

    max-width: 1226px;

    margin: 0 0 75px 20px;

    align-items: center;
}

.project__info-img {
    border-radius: 5px;
    object-fit: cover;


    min-width: 500px;
    max-width: 500px;
    height: 700px;
}

.project__info-texts {
    display: flex;
    flex-direction: column;

    row-gap: 50px;
}

.project__info-text {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;

}

.project__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;

    row-gap: 25px;

    max-width: 1240px;
    margin: 0 auto 125px;
}

.project__image {
    border-radius: 5px;
    width: 100%;

    max-height: 800px;
    min-height: 600px;

    object-fit: cover;
}

.project__video {
    max-width: 1240px;

    margin: 0 auto 50px;

    border-radius: 5px;
}

.project__error {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.project__button {
    border: 2px solid #ED202A;
    border-radius: 5px;
    background: transparent;

    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 18px 20px;

    transition: .3s;

    text-decoration: none;
    text-transform: uppercase;

    margin: 0 auto 30px;
    display: block;
}

.project__button:hover {
    cursor: pointer;

    box-shadow: inset 0px 0px 0px 1px #ED202A;
}

@media screen and (max-width: 1320px) {
    .project__cover {
        margin: 38px 20px 77px;
        max-width: calc(100% - 40px);
        max-height: 830px;

        position: relative;
    }

    .project__video {
        max-width: calc(100% - 40px);
        margin: 0 20px 50px;
    }

    .project__images {
        max-width: calc(100% - 40px);
        margin: 0 20px 125px;
    }

    .project__info-texts {
        max-width: (100% - 40px);
        margin: 0 20px;
    }

    .project__return {
        margin: 10px 0 0 20px;
    }
}

@media screen and (max-width: 1100px) {
    .project__info-text {
        font-size: 18px;
    }

    .project__button {
        font-size: 16px;

        padding: 16px 16px;
    }
}

@media screen and (max-width: 1000px) {
    .project__info-img {
        border-radius: 5px;
        object-fit: cover;


        width: 450px;
        height: 650px;
    }

    .project__info-text {
        font-size: 18px;
    }

    .project__cover {
        margin: 28px 20px 77px;
    }

    .project__return {
        margin: 30px 0 0 20px;
    }
}

@media screen and (max-width: 850px) {
    .project__images {
        display: flex;
        flex-direction: column;

        row-gap: 25px;
    }

    .project__button {
        font-size: 14px;

        padding: 12px 12px;
    }
}

@media screen and (max-width: 800px) {
    .project__info {
        max-width: (100% - 40px);
        margin: 0 20px 55px;

        column-gap: 25px;
    }

    .project__return {
        font-size: 18px;
    }

    .project__video {
        margin: 0 20px 40px;
    }
}

@media screen and (max-width: 650px) {
    .project__cover {
        margin-bottom: 60px;
    }

    .project__cover-text {
        font-size: 40px;
    }

    .project__info-texts {
        row-gap: 25px;
    }

    .project__cover-type {
        top: calc((100% - 40px) / 2 - 50px);
    }

    .project__cover-name {
        top: calc((100% - 45px) / 2);
        font-size: 45px;
    }

    .project__cover-square {
        bottom: 15px;
        font-size: 24px;
    }

    .project__button {
        font-size: 12px;

        padding: 10px 10px;
    }
}

@media screen and (max-width: 450px) {
    .project__cover {
        margin-top: 20px;
        margin-bottom: 55px;
    }

    .project__image {
        max-height: 400px;
        min-height: 300px;
    }

    .project__return {
        font-size: 14px;
    }

    .project__cover-text {
        font-size: 35px;
    }

    .project__info-texts {
        row-gap: 20px;
    }

    .project__cover-type {
        top: calc((100% - 35px) / 2 - 40px);
    }

    .project__cover-name {
        top: calc((100% - 40px) / 2);
        font-size: 40px;
    }

    .project__cover-square {
        bottom: 15px;
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    .project__info-text {
        font-size: 16px;
    }

    .project__cover-text {
        font-size: 28px;
    }

    .project__cover-type {
        top: calc((100% - 28px) / 2 - 35px);
    }

    .project__cover-name {
        top: calc((100% - 35px) / 2);
        font-size: 35px;
    }

    .project__cover-square {
        bottom: 10px;
        font-size: 17px;
    }
}

@media screen and (max-width: 350px) {
    .project__cover {
        margin-bottom: 45px;
    }

    .project__cover-square {
        bottom: 10px;
        font-size: 13px;
    }

    .project__button {
        font-size: 12px;

        padding: 8px 8px;
    }
}