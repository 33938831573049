.video__section {
    max-width: 100%;
    margin: 65px auto 0;
    padding: 65px 0 165px;

    background-color: #f5f5f5;
}

.video__container {
    max-width: 1220px;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.video {
    max-width: 1220px;
    width: 100%;
    border-radius: 5px;
}

.video__error {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    padding: 0;
    margin: 0;
}

@media screen and (max-width: 1320px) {
    .video__container {
        width: calc(100% - 60px);
        margin: 0 30px 0;
    }
}

@media screen and (max-width: 600px) {
    .video__container {
        width: calc(100% - 40px);
        margin: 0 20px 0;
    }

    .video__section {
        padding: 65px 0 105px;
    }
}